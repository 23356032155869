@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src:
    url('/fonts/proximanova-regular-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 500;
  src:
    url('/fonts/proximanova-medium-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-medium-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  src:
    url('/fonts/proximanova-semibold-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-semibold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: bold;
  font-weight: 700;
  src:
    url('/fonts/proximanova-bold-webfont.woff2') format('woff2'),
    url('/fonts/proximanova-bold-webfont.woff') format('woff');
}
